<h1 mat-dialog-title class="title">
    {{config.title}}
</h1>

<div mat-dialog-content>
    <p class="m-0" [innerHTML]="message"></p>
</div>

<div mat-dialog-actions >
        <button *ngIf="showCancel" mat-button  (click)="onClick(false)" class="cancel-button">{{config.buttonText?.cancel}}</button>&nbsp;
        <button *ngIf="showOk"  mat-raised-button color="primary" (click)="onClick(true)" class="ok-button">{{config.buttonText?.ok}}</button>
</div>

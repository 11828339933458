import { Injectable } from "@angular/core";
import { Observable, of, pipe } from "rxjs";
import { tap, map} from "rxjs/operators"

import { LoginContext } from "../../common/models/login.context";

import { UserPassword } from "../models/usr.pwd";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { PasswordResetReq } from "../models/password-reset.req";
import { SecurityContextService } from "./security-context.service";

@Injectable({providedIn:"root"})
export class AuthService {

  constructor(private contextService:SecurityContextService, private http:HttpClient) { }

  login(userPassword:UserPassword): Observable<LoginContext> {

    //Call the authentication to authenticate user. Get all information about the user and the token.
    //send out a notitification.
    const url = environment.apiUrl + "/auth"

    return this.http.post<LoginContext>(url, userPassword).pipe(tap(ctx => {
      if (ctx == null)
        return
      //logged in returned a response, set it to the contextService so that it could be notify.
      this.contextService.context = ctx
    }))

  }

  logout() {
      this.contextService.context = null
  }

  resetPasswordRequest(email: string): Observable<boolean> {
    const url = `${environment.apiUrl}/auth/reset?email=${email}`
    return this.http.get<boolean>(url) 
  }

  resetPassword(req: PasswordResetReq): Observable<boolean> {
    const url = `${environment.apiUrl}/auth/reset`
    return this.http.post<boolean>(url, req)
  }
}


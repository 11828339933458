export class Company {
  id:string
    name: string | null;
    address: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    modDateTime: string | null;
    modBy: string | null;
    url: string | null;
    email: string | null;
    phone: string | null;
  }

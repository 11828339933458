import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { ShareModule } from './common/share.module';
import { MainRoute } from './main.route';
import { SecurityModule } from './security/security.module';
import { DataTablesModule } from "angular-datatables";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from 'ngx-pagination';
import { PhonePipe } from './pipe/phone-pipe.pipe';
import { JwtModule } from '@auth0/angular-jwt';
//import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';

@NgModule({
  declarations: [
    AppComponent
   // PhonePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DataTablesModule,
    ShareModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
   // NgxPaginationModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(MainRoute, { onSameUrlNavigation: 'reload' })
    , ToastrModule.forRoot({ maxOpened: 1, autoDismiss: true })
    , JwtModule.forRoot({})

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

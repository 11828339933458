<!-- <div class="flex justify-between items-center bg-white-500 p-4" style="background-color: white;">
  <div class="flex">
    <img src="../../../../assets/img/Logo.jpg" />
  </div>
  <div class="flex">
    <div style="margin-right: 1rem;">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M17.2887 8.39905L17.2886 8.39911L17.2947 8.40525L20.8947 12.0052C20.8947 12.0052 20.8947 12.0052 20.8947 12.0053C21.0259 12.1365 21.0996 12.3145 21.0996 12.5001C21.0996 12.6857 21.0259 12.8637 20.8947 12.9949C20.8947 12.995 20.8947 12.995 20.8947 12.995L17.2982 16.5915C17.1665 16.7173 16.9908 16.7869 16.8086 16.7853C16.625 16.7837 16.4495 16.7101 16.3197 16.5803C16.1899 16.4505 16.1163 16.275 16.1147 16.0914C16.1131 15.9092 16.1827 15.7335 16.3085 15.6018L17.8567 14.0537L18.7102 13.2001H17.5031H8.3999C8.21425 13.2001 8.0362 13.1263 7.90493 12.9951C7.77365 12.8638 7.6999 12.6857 7.6999 12.5001C7.6999 12.3144 7.77365 12.1364 7.90493 12.0051C8.0362 11.8738 8.21425 11.8001 8.3999 11.8001H17.5031H18.7102L17.8567 10.9465L16.3051 9.39494L16.3051 9.39489L16.2989 9.38885C16.232 9.32428 16.1787 9.24704 16.142 9.16164C16.1053 9.07623 16.086 8.98438 16.0852 8.89143C16.0844 8.79849 16.1021 8.70632 16.1373 8.62029C16.1725 8.53426 16.2245 8.4561 16.2902 8.39038C16.3559 8.32465 16.4341 8.27267 16.5201 8.23748C16.6061 8.20228 16.6983 8.18457 16.7912 8.18538C16.8842 8.18619 16.976 8.2055 17.0614 8.24218C17.1468 8.27887 17.2241 8.3322 17.2887 8.39905ZM3.10493 4.80512C3.2362 4.67385 3.41425 4.6001 3.5999 4.6001C3.78555 4.6001 3.9636 4.67385 4.09488 4.80512C4.22615 4.9364 4.2999 5.11445 4.2999 5.3001V19.7001C4.2999 19.8857 4.22615 20.0638 4.09488 20.1951C3.9636 20.3263 3.78556 20.4001 3.5999 20.4001C3.41425 20.4001 3.2362 20.3263 3.10493 20.1951C2.97365 20.0638 2.8999 19.8857 2.8999 19.7001V5.3001C2.8999 5.11445 2.97365 4.9364 3.10493 4.80512Z" fill="#111928" stroke="#111928" />
      </svg>
    </div>
    <div>
      <p (click)="logOut()">Logout</p>
    </div>

  </div>
</div> -->

<nav class="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
  <div class="flex flex-wrap justify-between items-center">
    <div class="flex justify-start items-center">
      <button
        data-drawer-target="drawer-navigation"
        data-drawer-toggle="drawer-navigation"
        aria-controls="drawer-navigation"
        class="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        <svg
          aria-hidden="true"
          class="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <svg
          aria-hidden="true"
          class="hidden w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">Toggle sidebar</span>
      </button>
      <a href="/" class="flex items-center justify-between mr-4">
        <img src="../../../../assets/img/Logo.jpg" />
        {{companyInfo.companyName}}
      </a>

    </div>
    <div class="flex items-center lg:order-2">
      <!-- <button
        type="button"
        data-drawer-toggle="drawer-navigation"
        aria-controls="drawer-navigation"
        class="p-2 mr-1 text-gray-500 rounded-lg md:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
      >
        <span class="sr-only">Toggle search</span>
        <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path clip-rule="evenodd" fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path>
        </svg>
      </button> -->
      <!-- Notifications -->
      <button style="display: none;"
        type="button"
        data-dropdown-toggle="notification-dropdown"
        class="p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
      >
        <span class="sr-only">View notifications</span>
        <!-- Bell icon -->
        <svg
          aria-hidden="true"
          class="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
          ></path>
        </svg>
      </button>
      <!-- Dropdown menu -->
      <div
        class="hidden overflow-hidden z-50 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg dark:divide-gray-600 dark:bg-gray-700 rounded-xl"
        id="notification-dropdown"
      >
        <div
          class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-600 dark:text-gray-300"
        >
          Notifications
        </div>
        <div>
          <a
            href="#"
            class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600"
          >
            <div class="flex-shrink-0">
              <img
                class="w-11 h-11 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                alt="Bonnie Green avatar"
              />
              <div
                class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 rounded-full border border-white bg-primary-700 dark:border-gray-700"
              >
                <svg
                  aria-hidden="true"
                  class="w-3 h-3 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"
                  ></path>
                  <path
                    d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="pl-3 w-full">
              <div
                class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"
              >
                New message from
                <span class="font-semibold text-gray-900 dark:text-white"
                  >Bonnie Green</span
                >: "Hey, what's up? All set for the presentation?"
              </div>
              <div
                class="text-xs font-medium text-primary-600 dark:text-primary-500"
              >
                a few moments ago
              </div>
            </div>
          </a>
          <a
            href="#"
            class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600"
          >
            <div class="flex-shrink-0">
              <img
                class="w-11 h-11 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                alt="Jese Leos avatar"
              />
              <div
                class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-gray-900 rounded-full border border-white dark:border-gray-700"
              >
                <svg
                  aria-hidden="true"
                  class="w-3 h-3 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="pl-3 w-full">
              <div
                class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"
              >
                <span class="font-semibold text-gray-900 dark:text-white"
                  >Jese leos</span
                >
                and
                <span class="font-medium text-gray-900 dark:text-white"
                  >5 others</span
                >
                started following you.
              </div>
              <div
                class="text-xs font-medium text-primary-600 dark:text-primary-500"
              >
                10 minutes ago
              </div>
            </div>
          </a>
          <a
            href="#"
            class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600"
          >
            <div class="flex-shrink-0">
              <img
                class="w-11 h-11 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png"
                alt="Joseph McFall avatar"
              />
              <div
                class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-red-600 rounded-full border border-white dark:border-gray-700"
              >
                <svg
                  aria-hidden="true"
                  class="w-3 h-3 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="pl-3 w-full">
              <div
                class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"
              >
                <span class="font-semibold text-gray-900 dark:text-white"
                  >Joseph Mcfall</span
                >
                and
                <span class="font-medium text-gray-900 dark:text-white"
                  >141 others</span
                >
                love your story. See it and view more stories.
              </div>
              <div
                class="text-xs font-medium text-primary-600 dark:text-primary-500"
              >
                44 minutes ago
              </div>
            </div>
          </a>
          <a
            href="#"
            class="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600"
          >
            <div class="flex-shrink-0">
              <img
                class="w-11 h-11 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="Roberta Casas image"
              />
              <div
                class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-green-400 rounded-full border border-white dark:border-gray-700"
              >
                <svg
                  aria-hidden="true"
                  class="w-3 h-3 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="pl-3 w-full">
              <div
                class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"
              >
                <span class="font-semibold text-gray-900 dark:text-white"
                  >Leslie Livingston</span
                >
                mentioned you in a comment:
                <span
                  class="font-medium text-primary-600 dark:text-primary-500"
                  >@bonnie.green</span
                >
                what do you say?
              </div>
              <div
                class="text-xs font-medium text-primary-600 dark:text-primary-500"
              >
                1 hour ago
              </div>
            </div>
          </a>
          <a
            href="#"
            class="flex py-3 px-4 hover:bg-gray-100 dark:hover:bg-gray-600"
          >
            <div class="flex-shrink-0">
              <img
                class="w-11 h-11 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/robert-brown.png"
                alt="Robert image"
              />
              <div
                class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-purple-500 rounded-full border border-white dark:border-gray-700"
              >
                <svg
                  aria-hidden="true"
                  class="w-3 h-3 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="pl-3 w-full">
              <div
                class="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400"
              >
                <span class="font-semibold text-gray-900 dark:text-white"
                  >Robert Brown</span
                >
                posted a new video: Glassmorphism - learn how to implement
                the new design trend.
              </div>
              <div
                class="text-xs font-medium text-primary-600 dark:text-primary-500"
              >
                3 hours ago
              </div>
            </div>
          </a>
        </div>
        <a
          href="#"
          class="block py-2 text-md font-medium text-center text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-600 dark:text-white dark:hover:underline"
        >
          <div class="inline-flex items-center">
            <svg
              aria-hidden="true"
              class="mr-2 w-4 h-4 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
              <path
                fill-rule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            View all
          </div>
        </a>
      </div>

      <button
        type="button"
        class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        id="user-menu-button"
        aria-expanded="false"
        data-dropdown-toggle="dropdown"
      >
        <span class="sr-only">Open user menu</span>
        <img
          class="w-8 h-8 rounded-full"
          src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gough.png"
          alt="user photo"
        />
      </button>
      <!-- Dropdown menu -->
      <div
        class="hidden z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
        id="dropdown"
      >
        <div class="py-3 px-4">
          <span
            class="block text-sm font-semibold text-gray-900 dark:text-white"
            >{{userInfo.firstName}} {{userInfo.lastName}}</span
          >
          <span
            class="block text-sm text-gray-900 truncate dark:text-white"
            >{{userInfo.email}} </span
          >
        </div>


        <ul
          class="py-1 text-gray-700 dark:text-gray-300"
          aria-labelledby="dropdown"
        >
          <li>
            <a
             (click)="logOut()"
              class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >Sign out</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

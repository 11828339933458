

import { Routes } from "@angular/router";
import { NotFound } from "./common/components/not-found/not.found";



export const MainRoute:Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", loadChildren: () => import('./security/security.module').then(m => m.SecurityModule) },
  { path: "administration",  loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: "recruiter",  loadChildren: () => import('./recruiter/recruiter.module').then(m => m.RecruiterModule) },
  { path:"**", component:NotFound}

]

import { Injectable, Inject } from '@angular/core';
import { WINDOW } from './window.providers.service';

@Injectable()
export class WindowService {
  constructor(@Inject(WINDOW) private window: Window) {
  }
  getHostname(): string {
    return this.window.location.hostname.split('.')[0]
  }
}

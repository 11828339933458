import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CompanyService } from "src/app/admin/services/company.service";
import { LoggedInComponentBase } from "src/app/security/components/logged-in-component-base/logged.in.component.base";
import { AuthService } from "src/app/security/services/auth.service";
import { SecurityContextService } from "src/app/security/services/security-context.service";
import { STORAGE_ITEMS } from "../../STORAGE_ITEMS";
import { Company } from "src/app/admin/models/company";

@Component({
  selector: "header",
  templateUrl:"header.html"
})
export class Header extends LoggedInComponentBase  {
  userInfo:any
  companyInfo:any

  constructor(public securityContextService: SecurityContextService, private authService:AuthService, private route:Router, private compService: CompanyService ) {
    super(securityContextService)

   this.userInfo = this.securityContextService.context;
   this.compService.currentCompanySubject.subscribe(comp =>{
      this.companyInfo = comp;
      if(this.companyInfo.id === undefined){
        var rawData = JSON.parse(localStorage.getItem(STORAGE_ITEMS.COMPANY_CONTEXT.valueOf()) || '{}');
        this.companyInfo = rawData as Company;
      }
    });
  }

  @Input()
  userName: string
  @Input()
  userId:string

  logOut() {
    this.authService.logout()
    localStorage.clear();
    this.route.navigate(["/login"])
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Company } from '../models/company';

const baseUrl = `${environment.apiUrl}/`;

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }
  private _refreshrequired = new Subject<void>();
  private companySubject = new BehaviorSubject<Company>(new Company());
  currentCompanySubject = this.companySubject.asObservable();


  get Refreshrequired() {
    return this._refreshrequired;
  }

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(`${baseUrl}Company`);
  }



  /** GET hero by id. Will 404 if id not found */
  getCompanyById(id: string): Observable<Company> {
  const url = `${baseUrl}Company/${id}`;
  return this.http.get<Company>(url).pipe(
    tap(_value => {
      this.companySubject.next(_value)
    } ),

  );
}

  // getCompanyById(id: string): Observable<Company> {
  //   return this.http.get<Company>(`${baseUrl}Company/${id}`);
  // }

  create(params: any) {
   return this.http.post(`${baseUrl}Company`, params).pipe(
    tap(() => {
      this.Refreshrequired.next(params);

    })
  );
  }


  update(params: any) {
    return this.http.put(`${baseUrl}Company`, params).pipe(
     tap(() => {
       this.Refreshrequired.next(params);

     })
   );
   }

   delete(params: any) {
    return this.http.delete(`${baseUrl}Company?id=`+params).pipe(
     tap(() => {
       this.Refreshrequired.next(params);

     })
   );
   }
}

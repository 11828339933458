import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "confirm-dialog",
    templateUrl: "./confirm.component.html",
    styleUrls: ["./confirm.component.css"]
})
export class ConfirmComponent implements OnInit {
    showOk: boolean = true;
    showCancel: boolean = true;
    message: string = "";
  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public config: ConfirmDialogConfig
  ) { }
  ngOnInit(): void {
      this.message = this.config.message;
      this.showCancel = this.config.buttonText != null && this.config.buttonText.cancel.length > 0;
      this.showOk = this.config.buttonText != null && this.config.buttonText.ok.length > 0;
  }

  onClick(value: boolean): void {
    this.dialogRef.close(value);
  }
}

export class ConfirmConfig<T> {
    public position?:any
    public width?: string
    public height?: string
    public data?: T
}
export class ConfirmDialogConfig {
    constructor(public title: string, public message: string, public buttonText?: ButtonText) { }
}

export class ButtonText {
  constructor(public ok: string, public cancel: string) { }
}

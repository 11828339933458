import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ButtonText, ConfirmComponent, ConfirmConfig, ConfirmDialogConfig } from "../components/confirm-dialog/confirm.component";

@Injectable({providedIn:'root'})
export class ConfirmationService {

  constructor(private dialog: MatDialog) { }

  public defaultConfirm(config?: MatDialogConfig<ConfirmDialogConfig>): Observable<boolean> {
    var options = config != null ? config : this.getDefaultConfig();
    return this.confirm<ConfirmDialogConfig, boolean>(ConfirmComponent, options);
  }

  public simpleConfirm(message: string): Observable<boolean> {
    const config = this.getConfig("Confirm", message, "Ok", "Cancel", "180px", "580px")
    return this.defaultConfirm(config)
  }
    
  public confirm<configType, returnType>(component: ComponentType<unknown>, config: MatDialogConfig<configType>): Observable<returnType> {
    const dialog = this.dialog.open(component, config);
    return dialog.afterClosed().pipe(map(result => {
      return result
    }));
  }

  public getDefaultConfig(): MatDialogConfig<ConfirmDialogConfig> {
    const config = this.getConfig("Confirm","Do you want to continue?", "Ok", "Cancel",undefined,undefined) 
    return config
  }
  
  public getConfig(title: string = "Confirm"
    , message: string = "Do you want to cancel?"
    , okText: string = "Ok"
    , cancelText: string = "Cancel"
    , height: string = "235px"
    , width: string = "500px") {
    const config = new MatDialogConfig<ConfirmDialogConfig>()
    config.height = height;
    config.width = width;
    config.position = {left:'40%', top:'-30%'}


    config.data = new ConfirmDialogConfig(title, message, new ButtonText(okText, cancelText));
    return config
  }
}

import { Component, Directive, Input, OnInit } from "@angular/core";
import { LoginContext } from "../../../common/models/login.context";
import { SecurityContextService } from "../../services/security-context.service";

@Directive()
export abstract class LoggedInComponentBase {
  userName: string
  userId: string

  @Input()
  roles: string[] //empty string is allow ALL. Could be comma delimited.
  isVisible: boolean = true //default is show.
  constructor(public contextService: SecurityContextService) { }

  ngOnInit(): void {
    if (this.roles) { //there is a value int he roles property, so we have to match.
      this.contextService.loggedInContext.subscribe(ctx =>  this.checkContext(ctx))
    }
  }

  private checkContext(ctx: LoginContext|null) {
    if (ctx == null || ctx.expiration <= (new Date())) {
      this.isVisible = false
      return
    }

    const result = ctx.roleClaims.filter(role => this.roles.includes(role.type)) //search existing user permission that matches 'this.roles'
    this.isVisible = result && result.length > 0 //there is matching permission, then we show the control. Otherwise, it's not visible.
  }

}

<div class="col-lg-12">
  <div class="white_box mb_30">
    <div class="box_header ">
      <div class="main-title">
        <h3 class="mb-0">Faq</h3>
      </div>
    </div>
    <!-- accordian -->
    <div class="accordion accordion_custom mb_50" id="accordion_ex">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <a href="#" class="btn" type="button" data-bs-toggle="collapse"
               data-bs-target="#collapseOne" aria-expanded="true"
               aria-controls="collapseOne">
              Example 0
            </a>
          </h2>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
             data-parent="#accordion_ex">
          <div class="card-body">
            <p>
              Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.

              Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <a href="#" class="btn collapsed" type="button"
               data-bs-toggle="collapse" data-bs-target="#collapseTwo"
               aria-expanded="false" aria-controls="collapseTwo">
              Example 1
            </a>
          </h2>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
             data-parent="#accordion_ex">
          <div class="card-body">
            <p>
              Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.

              Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <a href="#" class="btn collapsed" type="button"
               data-bs-toggle="collapse" data-bs-target="#collapseThree"
               aria-expanded="false" aria-controls="collapseThree">
              Example 2
            </a>
          </h2>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
             data-parent="#accordion_ex">
          <div class="card-body">
            <p>
              Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.

              Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree3">
          <h2 class="mb-0">
            <a href="#" class="btn collapsed" type="button"
               data-bs-toggle="collapse" data-bs-target="#collapseThree3"
               aria-expanded="false" aria-controls="collapseThree">
              Example 3
            </a>
          </h2>
        </div>
        <div id="collapseThree3" class="collapse" aria-labelledby="headingThree3"
             data-parent="#accordion_ex">
          <div class="card-body">
            <p>
              Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.

              Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree3">
          <h2 class="mb-0">
            <a href="#" class="btn collapsed" type="button"
               data-bs-toggle="collapse" data-bs-target="#collapseThree4"
               aria-expanded="false" aria-controls="collapseThree">
              Example 4
            </a>
          </h2>
        </div>
        <div id="collapseThree4" class="collapse" aria-labelledby="headingThree3"
             data-parent="#accordion_ex">
          <div class="card-body">
            <p>
              Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.

              Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree3">
          <h2 class="mb-0">
            <a href="#" class="btn collapsed" type="button"
               data-bs-toggle="collapse" data-bs-target="#collapseThree5"
               aria-expanded="false" aria-controls="collapseThree">
              Example 5
            </a>
          </h2>
        </div>
        <div id="collapseThree5" class="collapse" aria-labelledby="headingThree3"
             data-parent="#accordion_ex">
          <div class="card-body">
            <p>
              Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.

              Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- accordian -->
  </div>

import { HttpClientModule } from "@angular/common/http";
import { MatTableModule } from "@angular/material/table";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";

import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { Header } from "./components/header/header";
import { HelloWorld } from "./components/hello-world/hello.world";
import { MainLayout } from "./components/main.layout/main.layout";
import { NotFound } from "./components/not-found/not.found";
import { SideBar } from "./components/side-bar/side-bar";
import { NotificationMessageService } from "./services/notification.message.service";
import { ConfirmationService } from "./services/confirmation.service";
import { ConfirmComponent } from "./components/confirm-dialog/confirm.component";
import { CommonModule } from "@angular/common";
import { WINDOW_PROVIDERS } from "./services/window.providers.service";
import { WindowService } from "./services/window.services";


@NgModule({
  declarations: [MainLayout
    , SideBar, Header
    , HelloWorld, NotFound
    , ConfirmComponent],
  imports: [
      FormsModule
    , ReactiveFormsModule
    , RouterModule
    , HttpClientModule
    , MatTableModule
    , MatDialogModule
    , MatButtonModule
    , MatMenuModule
    , MatIconModule
    , CommonModule
  ],
  exports: [
      FormsModule
    , HttpClientModule
    , MainLayout
    , SideBar
    , Header
    , NotFound
    , ReactiveFormsModule
    , MatTableModule
    , MatDialogModule
    , MatMenuModule
    , MatButtonModule
    , MatIconModule
   ],
  providers: [
    NotificationMessageService,
     ConfirmationService, WINDOW_PROVIDERS, WindowService
  ]
})
export class ShareModule {
  
}

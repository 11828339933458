import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject"
import { CompanyService } from "../../admin/services/company.service";
import { LoginContext } from "../../common/models/login.context"
import { WindowService } from "../../common/services/window.services";
import { STORAGE_ITEMS } from "../../common/STORAGE_ITEMS";


@Injectable({ providedIn: 'root' })
export class SecurityContextService {

  private loggedInontextSubject$: BehaviorSubject<LoginContext | null> = new BehaviorSubject<LoginContext | null>(null)
  //private loginContext: LoginContext|null;
  public get loggedInContext(): BehaviorSubject<LoginContext | null> {
    return this.loggedInontextSubject$
  }

  public get context(): LoginContext|null {
    return this.getLoginContext()
  }

  public set context(ctx: LoginContext|null) {
    this.setContext(ctx)
  }

  constructor(private companyService:CompanyService, private windowService:WindowService) {

    const item = this.getLoginContext()
    if (item == null)
      return
    this.setContext(item)
  }

  private getLoginContext(): LoginContext|null{

    const item = localStorage.getItem(STORAGE_ITEMS.SECURITY_CONTEXT.valueOf())
    if (item == null)
      return null

    return JSON.parse(item)
  }

  private setContext(ctx: LoginContext | null) {
      if (ctx == null) {
        localStorage.removeItem(STORAGE_ITEMS.SECURITY_CONTEXT.valueOf())
        return;
    }
    localStorage.setItem(STORAGE_ITEMS.SECURITY_CONTEXT.valueOf(), JSON.stringify(ctx))
    this.loggedInontextSubject$.next(ctx)
  }
  private getCurrentCompanyId() {
    const host = this.windowService.getHostname();
    return this.companyService.getCompanyById(host)
  }


}
